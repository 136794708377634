<template>
  <div 
    class="dropdown w-100"
  >
    <label v-if="label">{{ label }}</label>
    <br/>
    <a 
      id="user-select-dropdown"
      href="#" 
      class="btn bg-gradient-primary dropdown-toggle d-flex 
        justify-content-between align-items-center px-3 overflow-visible w-100"
      :class="{ 'disabled': disabled }"
      data-bs-toggle="dropdown"
      :disabled="disabled"
    >
      <span
        v-if="!noOptionSelected"
        class="d-flex align-items-center"
      >
        <div
          class="mt-n4"
        >
          <profile-picture
            v-if="showImages"
            :width-and-height-in-pixels="40"
            :background-image-url="selectedImage"
          />
        </div>
        <span
          class="ms-2"
        >
          {{ selectedLabel }} 
        </span>
      </span>

      <span
        v-if="noOptionSelected"
      >
        {{ placeholderLabel }}
      </span>
    </a>
    <div
      class="dropdown-menu w-100"
      :style="{
        border: `1px solid ${borderColor ?? 'transparent'}`,
      }"
    >
      <div
        class="dropdown-content"
      >
        <div
          class="dropdown-content-header"
        >
          <input
            v-if="showSearchBar"
            class="form-control border px-2"
            placeholder="Vul zoektekst in"
            type="text"
            @input="setFilterText"
          />
        </div>
        <ul>
          <li
            v-for="option in options.filter(o =>
              o.label.toLowerCase().includes(filterText.toLowerCase())
            )"
            :key="option.id"
            class="px-1 py-2 d-flex align-items-center"
            @click="selectOption(option.value)"
          >

            <profile-picture
              v-if="showImages"
              :width-and-height-in-pixels="40"
              :background-image-url="option.image ?? null"
            />
            <span
              class="ms-2"
            >
              {{ option.label }} 
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from './ProfilePicture.vue';

export default {
  name: 'Dropdown',

  components: {
    ProfilePicture
  },

  props: {
    label: { type: String, default: undefined },
    value: { type: [Object, String], default: undefined },
    options: { type: Array, required: true },
    borderColor: { type: String, default: null },
    
    disabled: { type: Boolean, default: false },
    showSearchBar: { type: Boolean, default: true },
    showImages: { type: Boolean, default: true },
    autoSelectIfSingleOption: { type: Boolean, default: false },
    displayLastSelectedOption: { type: Boolean, default: true, },
    floatMenuRight: { type: Boolean, default: false, },

    placeholderLabel: { type: String, default: 'Geen optie geselecteerd' },
  },

  emits: [
    'update:value',
    'option-selected'
  ],
  
  data() {
    return {
      selectedOption: {
        value: undefined,
        label: '',
        image: undefined
      },
      filterText: ''
    }
  },

  computed: {
    noOptionSelected() { 
      return (
        !this.displayLastSelectedOption ||
        this.selectedOption.value === undefined
      );
    },

    currentValueHandler: {
      get() { return this.selectedValue ?? this.value },
      set(value) {
        const option = this.getOption(value);
        if (option !== undefined) {
          this.updateDisplayedOption(option);
          this.$emit('update:value', value);
          this.$emit('option-selected', option);
        }
      }
    },

    selectedLabel() { return this.selectedOption.label; },
    selectedValue() { return this.selectedOption.value; },
    selectedImage() { return this.selectedOption.image; },
    hasSingleRemainingOption() { return this.options.length == 1 && this.options[0] != undefined; }
  },

  mounted() {
    this.initialize();
  },

  updated() {
    if (
      this.autoSelectIfSingleOption &&
      this.hasSingleRemainingOption
    ) {
      this.currentValueHandler = this.options[0].value;
    }

    const option = this.getOption(this.currentValueHandler);
    if (option !== undefined) this.updateDisplayedOption(option);
  },

  methods: {
    initialize() {
      const initialOption = this.getOption(this.initialValue);

      if (initialOption !== undefined) {
        this.updateDisplayedOption(initialOption);
      }
    },

    getOption(value) {
      return this.options.find(o => o.value === value);
    },

    updateDisplayedOption(option) {
      this.selectedOption.value = option.value;
      this.selectedOption.label = option.label;
      this.selectedOption.image = option.image;
    },

    selectOption(id) {
      this.currentValueHandler = id;
    },
    
    getBackgroundImageUrl(url) {
      return url != null
      ? `url(${url})`
      : 'url(/assets/img/company/user_placeholder.png)';
    },

    setFilterText(inputEvent)
    {
      this.filterText = inputEvent.target.value;
    },
  }
}
</script>

<style lang="scss" scoped>
  .dropdown {
    position: relative;
      
    .dropdown-toggle::after {
      content: '\e145';
      font-family: 'Material Icons Round';
      font-size: 2.1em;
    }

    .dropdown-content {
      position: relative;

      padding-top: 10px;

      ul {
        padding-top: 0px;
        padding-inline: 5px;
      }
    }
  }
  
  .dropdown-menu {
    max-height: 350px;
    overflow: scroll;
    padding-top: 0px;

    &::before {
      content: none;
    }

    .dropdown-content-header {
      background: #fff;
      position: sticky;
      z-index: 1;
      top: 0px;
    
      input {
        border-color: #eee;
        width: calc(100% - 20px);
        margin: 10px;
      }
    }
  }
</style>